import solor from "../assets/solor1-19154838.jpeg";
import plumbing from "../assets/plumping.jpg";

function About() {
  return (
    <>
      <div className="about" data-aos="zoom-in">
        <div className="aboutDetail">
          <h1>
            We are <br />
            <span> AMZ heating & plumbing</span>
          </h1>
          <h4>we are well versed in all aspects of home energy</h4>
          <p>
            The company offers various services for various needs: boiler
            installations and repairs, Electric installations, heating controls,
            insulation, and much more. M S heating and plumbing also works with
            local governments to help tackle fuel poverty
          </p>
        </div>
        <div className="aboutImg ">
          <img src={solor} alt="" />
        </div>
      </div>

      <div className="secondabout" data-aos="zoom-in">
        <div className="secondImg">
          <img src={plumbing} alt="" />
        </div>
        <div className="secondDetail ">
          <h1>Our Community</h1>
          <p>
            AMZ heating and plumbing is committed to help individuals and
            families in the UK, by providing them with the most exceptional
            services. We are an energy company that focuses on efficiency and
            savings on energy usage in a domestic dwellings and commercial
            properties. Our energy experts have a wealth of knowledge in their
            respective domains. Should you require an engineer or an energy
            assessor or a Retrofit Coordinator we shall provide you with all.
          </p>
        </div>
      </div>
    </>
  );
}

export default About;
