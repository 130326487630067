import s1 from "../assets/s1.jpeg";
import s2 from "../assets/s2.jpeg";
import s3 from "../assets/s3.jpg";
import s4 from "../assets/s4.jpg";
import s5 from "../assets/s5.jpg";
import s6 from "../assets/s6.jpg";
import s7 from "../assets/s7.jpeg";
import s8 from "../assets/s8.jpg";
import s9 from "../assets/s9.webp";
import s10 from "../assets/s10.jpg";

function Services() {
  return (
    <div className="serv" >
      <div className="S1"  data-aos="fade-up">
        <div className="content">
          <h1>Boiler Installation</h1>
          <p>
            All of the boiler’s connected pipes, valves, fittings, flanges,
            firing equipment, controls, appurtenances, and auxiliaries are
            considered part of the boiler’s installation. Boiler field assembly
            is covered by the adverb.
          </p>
        </div>
        <img src={s1} alt="" />
      </div>
      <div className="S2" data-aos="fade-up">
        <img src={s2} alt="" />
        <div className="content">
          <h1>Heat Control</h1>
          <p>
            Effective heating controls are a requirement for a boiler-powered
            central heating system. You can save energy consumption with the
            help of smart control usage by making sure each room is at the right
            temperature for comfort. This will keep your home cosy and reduce
            your energy costs
          </p>
        </div>
      </div>
      <div className="S1" data-aos="fade-up">
        <div className="content">
          <h1>Loft Insulations​</h1>
          <p>
            Installing insulation in the loft has several key benefits including
            increased energy efficiency, lower heating bills, and could even
            increase the value of the home. These benefits combined with its
            relatively cheap cost and ease of install make it a perfect choice
            for improved comfort.
          </p>
        </div>

        <img src={s3} alt="" />
      </div>
      <div className="S2" data-aos="fade-up">
        <img src={s4} alt="" />
        <div className="content">
          <h1>Ventilation</h1>
          <p>
            Ventilation helps your home rid itself of moisture, smoke, cooking
            odours, and indoor pollutants. Structural ventilation controls heat
            levels in the attic, moderates dampness in the crawlspace and
            basement, and keeps moisture out of uninsulated walls.
          </p>
        </div>
      </div>
      <div className="S1" data-aos="fade-up">
        <div className="content">
          <h1>Air Source Heat Pump</h1>
          <p>
            Low Carbon Footprint Save Money on Energy Bills Eligible for RHI Can
            Be Used for Heating and Cooling Can Be Used for Space Heating and
            Hot Water High Seasonal Coefficient of Performance (SCOP) Easy
            Installation Process Low Maintenance Long Lifespan (5-Year
            Warranties) No Fuel Storage Needed
          </p>
        </div>
        <img src={s5} alt="" />
      </div>
      <div className="S2" data-aos="fade-up">
        <img src={s6} alt="" />
        <div className="content">
          <h1>Electric Storage Heater</h1>
          <p>
            A boost function to provide additional heat when needed; Some models
            incorporate a fan to evenly distribute heat; Many modern storage
            heaters have programmable heating schedules to allow you to
            customise your heating requirements; Larger ones are available for
            bigger rooms; Some come with thermostatic controls, so you can keep
            a room at the desired temperature.
          </p>
        </div>
      </div>
      <div className="S1" data-aos="fade-up">
        <div className="content">
          <h1>Solar Panel</h1>
          <p>
            Renewable Energy Source. Among all the benefits of solar panels, the
            most important thing is that solar energy is a truly renewable
            energy source. Reduces Electricity Bills. Diverse Applications. Low
            Maintenance Costs. Technology Development.
          </p>
        </div>

        <img src={s7} alt="" />
      </div>
      <div className="S2" data-aos="fade-up">
        <img src={s8} alt="" />
        <div className="content">
          <h1>Under Floor Insulation</h1>
          <p>
            Floor insulation will help keep your home more comfortable
            throughout the year and reduce the need for artificial heating and
            cooling. In other words, when it’s used along with insulation in the
            ceiling and walls, floor insulation can help you save money on your
            energy bills all year round. Insulating your floors is a good
            starting point for reducing your home’s carbon emissions, and
            lowering your utility bills.
          </p>
        </div>
      </div>
      <div className="S1" data-aos="fade-up">
        <div className="content">
          <h1>Internal Wall Insulation</h1>
          <p>
            As much as 35% of heat loss occurs through insufficiently insulated
            walls. Therefore installing a thermal layer inside the home can
            significantly reduce your energy bills. It will improve ventilation
            and reduce condensation and working with the right experts mean you
            can make it hassle free. Interior wall insulation functions the same
            as exterior wall insulation. That is, it reduces the rate of heat
            transferring from room to room. Without interior insulation, outside
            air will enter your home easily, raising and lowering the
            temperature of your rooms to uncomfortable levels.
          </p>
        </div>

        <img src={s9} alt="" />
      </div>
      <div className="S2" data-aos="fade-up">
        <img src={s10} alt="" />
        <div className="content">
          <h1>External Wall Insulation</h1>
          <p>
            Improve Thermal Comfort. Reduces Electricity Bills. Reduce Energy
            Bills. Improve External Aesthetics. Reduce Carbon Footprint.
            Increase Lifespan of Building Fabric. Acoustic Insulation and Fire
            Resistance. Minimise Thermal Bridging. Reduce Damp and Condensation.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Services;
