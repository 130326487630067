import axios from "axios";
import { useState } from "react";

function Apply() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [post, setPost] = useState("");
  const [address, setAddress] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to control success popup visibility
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false); // State to control error popup visibility
  const [loading, setLoading] = useState(false); // State to control loading spinner

  const formData = { name, email, mobile, post, address };

  function ApplySubmit(e) {
    e.preventDefault();
    setLoading(true); // Set loading to true when form submission starts
    axios
      .post("https://amzheating.co.uk/apply", formData)
      .then((res) => {
        setLoading(false);
        setIsPopupOpen(true);
        setName("");
        setEmail("");
        setMobile("");
        setPost("");
        setAddress("");
        console.log("ok");
      })
      .catch((error) => {
        setLoading(false);
        setIsErrorPopupOpen(true);
        console.log(error);
      });
  }

  return (
    <>
      {isPopupOpen && (
        <POP
          message="Thank you for contacting our team. We will get in touch with you soon."
          closePopup={() => setIsPopupOpen(false)}
        />
      )}
      {isErrorPopupOpen && (
        <POP
          message="Error submitting the form. try again later."
          closePopup={() => setIsErrorPopupOpen(false)}
        />
      )}
      <div className="apply" data-aos="zoom-in">
        <h1>Grant Application</h1>
        <div className="applyform">
          <form action="" className="applyForm" onSubmit={ApplySubmit}>
            <label htmlFor="name">Name</label>
            <br />
            <input
              required
              type="text"
              name="name"
              id="name"
              value={name}
              placeholder="your name"
              onChange={(e) => setName(e.target.value)}
            />
            <br />
            <label htmlFor="email">Email</label>
            <br />
            <input
              required
              type="email"
              name="email"
              id="email"
              value={email}
              placeholder="your email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <br />
            <label htmlFor="mobile">Mobile</label>
            <br />
            <input
              required
              type="number"
              name="mobile"
              id="mobile"
              value={mobile}
              placeholder="07*******"
              onChange={(e) => setMobile(e.target.value)}
            />
            <br />
            <label htmlFor="post">Post Code</label>
            <br />
            <input
              required
              type="text"
              name="post"
              id="post"
              value={post}
              placeholder="area code "
              onChange={(e) => setPost(e.target.value)}
            />
            <br />
            <label htmlFor="address">Address</label>
            <br />
            <input
              required
              type="text"
              name="address"
              id="address"
              value={address}
              placeholder="home & office"
              onChange={(e) => setAddress(e.target.value)}
            />
            <br />
            <button type="submit" disabled={loading}>
              {loading ? "Sending..." : "Send"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Apply;

function POP({ message, closePopup }) {
  return (
    <div className="popup">
      <div className="popup-content">
        <button className="close-btn" onClick={closePopup}>
          &times;
        </button>
        <h1>{message}</h1>
      </div>
    </div>
  );
}
