
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import Apply from "./components/Apply";
import Contact from "./components/Contact";
import Services from "./components/Services";
import About from "./components/About";

function MainPage(){
    return (
      <>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/apply" element={<Apply />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/service" element={<Services />} />
            <Route path="/about" element={< About />} />
          </Routes>
        </BrowserRouter>
      </>
    );
}
export default MainPage