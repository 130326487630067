import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";
import AOS from "aos";


import home from "../assets/home.jpg";
import quotes from "../assets/quotes.webp";
import s1 from "../assets/s1.jpeg";
import s2 from "../assets/s2.jpeg";
import s3 from "../assets/s3.jpg";
import s4 from "../assets/s4.jpg";
import s5 from "../assets/s5.jpg";
import s6 from "../assets/s6.jpg";
import s7 from "../assets/s7.jpeg";
import s8 from "../assets/s8.jpg";
import s9 from "../assets/s9.webp";
import s10 from "../assets/s10.jpg";
import img2 from "../assets/img2.jpg";
 

const data = [
  {
    pic: s1,
    heading: "Boiler Installation",
    para: "Custom boiler solutions for every household",
  },
  {
    pic: s2,
    heading: "Heat Control",
    para: "First time central heating for every household",
  },
  {
    pic: s3,
    heading: "Loft Insulations",
    para: "Custom insulation solutions",
  },
  {
    pic: s4,
    heading: "Ventilations",
    para: "Custom ventilations solutions",
  },
  {
    pic: s5,
    heading: "Air Source",
    para: "Custom air source solutions",
  },
  {
    pic: s6,
    heading: "Electric Storage Heater",
    para: "Electric Storage Heater for every household",
  },
  {
    pic: s7,
    heading: "Solar Panel",
    para: "Solar panel solutions for every house hold.",
  },
  {
    pic: s8,
    heading: "Under Floor Insulation",
    para: "Under Floor insulation solutions for every household",
  },
  {
    pic: s9,
    heading: "Internal Wall Insulation",
    para: "Solar panel solutions for every house hold.",
  },
  {
    pic: s10,
    heading: "External Wall Insulation",
    para: "Custom air source solutions",
  },
];

function Home({ handlePage }) {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <>
      <div className="home" data-aos="zoom-in">
        <div className="homeDetail">
          <h1>
            Welcome to <br /> <span> AMZ Heating & Plumbing</span>
          </h1>
          <p>Apply for energy measures free of charge under the ECO4 scheme</p>
          <p>
            We are gas safe registered engineers and have 15 years plus
            experience
          </p>
          <p className="second">
            Tailored solutions to provide a range of energy efficient measures
            that will help homes save on their energy bills and reduce their
            carbon footprint. As PAS2030 accredited installer, we can help you
            access ECO4 funding, and see your chosen energy efficiency measure
            installed within weeks. Apply now.
          </p>
          <div className="homebtn">
            <button className="firstbtn" onClick={() => handlePage("contact")}>
              Talk to us
            </button>
            <button className="secondbtn" onClick={() => handlePage("apply")}>
              Apply now
            </button>
          </div>
        </div>
        <div className="homeImg">
          <img src={home} alt="" />
        </div>
      </div>
      <Quotes />
      <Service handlePage={handlePage} />
      <Message />
      <Think />
    </>
  );
}

export default Home;

function Quotes() {
  return (
    <div className="quoteCon" data-aos="fade-up">
      <div className="quotes">
        <h1>Do you qualify for a Free ECO4 Grant?</h1>
        <img src={quotes} alt="" />
      </div>
    </div>
  );
}
function Service({ handlePage }) {
  return (
    <>
      <h1 className="serviceHeading" data-aos="flip-up">
        We specialise in these industries
      </h1>

      <div className="serviceCon">
        {data.map((item, index) => (
          <div className="service" data-aos="fade-up" key={index}>
            <img src={item.pic} alt={item.heading} />
            <h3>{item.heading}</h3>
            <p>{item.para}</p>
            <div className="serBtn">
              <button onClick={() => handlePage("serv")}>Learn more</button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

function Message() {
  return (
    <div className="messege">
      <div className="messegeImg" data-aos="fade-right">
        <img src={img2} alt="solor img" />
      </div>
      <div className="messegeDetail" data-aos="fade-left">
        <h1>Regarding Team of Creative Individuals</h1>
        <p>
          Electric services and the installation of Energy Efficiency Measures
          are offered by us. Employ certified Gas and Electric Engineers for
          residential and commercial projects to save your energy costs.
          Additionally, we provide consultations with our experienced engineers
          who are happy to help any client wishing to make improvements to their
          house or place of business to reduce emissions!
        </p>
      </div>
    </div>
  );
}
function Think() {
  const [loading, setLoading] = useState(true);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="think">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <h1>What do our customers think?</h1>
          <p>
            Our customers highly rate the installations we have done for them.
            We don’t like to claim we can provide you with the best service, we
            let out customers do it for us
          </p>
          <h6>AMZ</h6>
          <Slider {...settings}>
            <div className="sliders">
              <h2>Engr. Idrees Faiz</h2>
              <p>
                I would highly recommend this company. They have just installed
                a combi boiler and heating system from scratch. The engineers
                were very polite, hard working and did an excellent job. From
                start to finish They did a brilliant and and my loft insulation
                was completed as well.
              </p>
            </div>
            <div className="sliders">
              <h2>Mrs Jenny</h2>
              <p>
                This company did an excellent job installing my new combi boiler
                and heating system. The engineers were not only polite and
                hardworking but also displayed great skill in their work. The
                project was executed flawlessly from start to finish, and they
                even took care of my loft insulation. I am extremely satisfied
                with their service and would highly recommend them.
              </p>
            </div>
            <div className="sliders">
              <h2>Mr Charles</h2>
              <p>
                I highly recommend this company for their outstanding service.
                They recently installed a combi boiler and heating system from
                scratch in my home. The engineers were courteous, hardworking,
                and demonstrated excellent workmanship throughout the process.
                The entire project was handled professionally from beginning to
                end, and they also completed my loft insulation efficiently.
              </p>
            </div>
          </Slider>
        </>
      )}
    </div>
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "#1b6392",
        borderRadius: "50%",
        width: 30, // Increased width
        height: 30, // Increased height
        paddingLeft: 5,
        paddingTop: 5.5,
      }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "#1b6392",
        borderRadius: "50%",
        width: 30, // Increased width
        height: 30, // Increased height
        paddingLeft: 5,
        paddingTop: 5.5,
      }}
      onClick={onClick}
    />
  );
}
