import "aos/dist/aos.css";
import AOS from "aos";
import CallIcon from "@mui/icons-material/Call";
import {
  Call,
  EmailOutlined,
  FacebookOutlined,
  Instagram,
  PlaceOutlined,
} from "@mui/icons-material";
import "./App.css";
import { useState, useEffect } from "react";
import Home from "./components/Home";
import About from "./components/About";
import talk from "./assets/talk-2e215914.png";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import Contact from "./components/Contact";
import Services from "./components/Services";
import Apply from "./components/Apply";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const [page, setPage] = useState("home");

  const handlePage = (page) => {
    setPage(page);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <header>
        <div className="headDetail">
          <h1>AMZ</h1>
          <p>HEATING & PLUMBING</p>
        </div>
        <a></a>
        <a href="tel:07525786845" className="headContact">
          <CallIcon sx={{ color: "#1b6392", fontSize: 28 }} />
          <p>07525786845</p>
        </a>
      </header>
      <nav>
        <div
          className="links"
          onClick={() => handlePage("home")}
          data-aos="flip-up"
          data-aos-duration="600"
        >
          Home
        </div>
        <div
          className="links"
          onClick={() => handlePage("about")}
          data-aos="flip-up"
          data-aos-duration="1200"
        >
          About Us
        </div>
        <div
          className="links"
          onClick={() => handlePage("serv")}
          data-aos="flip-up"
          data-aos-duration="1800"
        >
          Services
        </div>
        <div
          className="links"
          onClick={() => handlePage("contact")}
          data-aos="flip-up"
          data-aos-duration="2400"
        >
          Contact Us
        </div>
        <div
          className="links"
          onClick={() => handlePage("apply")}
          data-aos="flip-up"
          data-aos-duration="3000"
        >
          Apply
        </div>
      </nav>
      {page === "home" && <Home handlePage={handlePage} />}
      {page === "about" && <About />}
      {page === "contact" && <Contact />}
      {page === "serv" && <Services handlePage={handlePage} />}
      {page === "apply" && <Apply />}
      <Talk handlePage={handlePage} />
      <Footer handlePage={handlePage} />
    </>
  );
}

export default App;

function Talk({ handlePage }) {
  return (
    <div className="talk">
      <img src={talk} alt="talking" id="talk" />
      <div className="talkbtn">
        <h1>Let us know how we can help you</h1>
        <div className="innerBtn">
          <button data-aos="fade-up" onClick={() => handlePage("contact")}>
            Talk to us
          </button>
          <button
            id="talkbtn"
            data-aos="fade-up"
            onClick={() => handlePage("about")}
          >
            About us
          </button>
        </div>
      </div>
    </div>
  );
}

function Footer({ handlePage }) {
  return (
    <footer>
      <div className="detail" data-aos="fade-right">
        <h2>
          Can we help you with <br />
          anything?
        </h2>
        <p>Let's hear all about it!</p>
        <FacebookOutlined
          sx={{ fontSize: 45, color: "#1b6392" }}
          id="detailIcon"
        />
        <Instagram sx={{ fontSize: 45, color: "#1b6392" }} id="detailIcon" />
      </div>
      <div className="other" data-aos="fade-right">
        <h2>Other Links</h2>
        <ul>
          <li onClick={() => handlePage("home")}>Home</li>
          <li onClick={() => handlePage("about")}>About Us</li>
          <li onClick={() => handlePage("contact")}>Contact Us</li>
          <li onClick={() => handlePage("apply")}>Apply</li>
        </ul>
      </div>
      <div className="conInfo" data-aos="fade-right">
        <h2>Contact Information</h2>
        <div className="footerIcon">
          <Call sx={{ fontSize: 30, color: "#1b6392" }} />
          <a href="tel:07525786845">07525786845</a>
        </div>
        <div className="footerIcon">
          <EmailOutlined sx={{ fontSize: 30, color: "#1b6392" }} />
          <a href="mailto:amzheatingservices@gmail.com">
            amzheatingservices@gmail.com
          </a>
        </div>
        <div className="footerIcon">
          <PlaceOutlined sx={{ fontSize: 30, color: "#1b6392" }} />
          <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
            {address}
          </a>
        </div>
      </div>
      <div className="Loc" data-aos="fade-right">
        <div className="headDetail">
          <h1>AMZ</h1>
          <p>HEATING & PLUMBING</p>
        </div>
        <MapComponent />
      </div>
    </footer>
  );
}

const address = "51 Sewell Road, BD39TD";
const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
  address
)}`;

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const MapComponent = () => {
  const position = [53.7997, -1.5492];

  return (
    <MapContainer center={position} zoom={24} className="map">
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker position={position}>
        <Popup>51 Sewell Road, BD39TD</Popup>
      </Marker>
    </MapContainer>
  );
};
